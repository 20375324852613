<template>
  <div id="nav">
    <div class="appBar">
      <div class="logo"></div>
    </div>
    <ul class="posts">
      <li v-for="post in postList" :key="post" @click="loadPost(post.id)">
        <router-link
          class="post"
          :to="{ name: 'Editor', params: { id: post.id } }"
          >{{ post.subject }}</router-link
        >
      </li>
    </ul>
    <div class="button">
      <button class="newPost" @click="newPost">
        <span>新文章</span>
      </button>
    </div>
  </div>
  <Editor
    :post="onEditingPost"
    @update:postUpdate="updatePost"
    v-model:content="postForUpdate"
  />
</template>
<script setup>
import Editor from "@/views/Editor.vue"
import { useRoute, useRouter } from "vue-router"
import { onMounted, ref } from "vue"

const route = useRoute()
const router = useRouter()

const onEditingPost = ref()
const postForUpdate = ref()

const postList = ref([])

onMounted(async () => {
  getPostList()
  await router.isReady()
  loadPost(route.params.id)
})

const getPostList = () => {
  postList.value = JSON.parse(localStorage.getItem("postList"))
  if (!postList.value) {
    postList.value = []
    newPost()
  } else {
  }
}

const newPost = () => {
  // 建立 ID
  const newPostId = Math.floor(1000 + Math.random() * 9000)
  postList.value.push({ id: newPostId, subject: "我的新文章" })
  localStorage.setItem("postList", JSON.stringify(postList.value))

  // 建立新文章
  const defaultContext = { id: newPostId, subject: "我的新文章", context: "" }
  localStorage.setItem(newPostId, JSON.stringify(defaultContext))

  // 跳轉到新文章
  loadPost(newPostId)
}

const loadPost = (post) => {
  const postDetail = JSON.parse(localStorage.getItem(post))
  onEditingPost.value = postDetail
  router.push({ name: "Editor", params: { id: onEditingPost.value.id } })
}

const updatePost = () => {
  // 更新文章列表
  let elementIndex = postList.value.findIndex(
    (obj) => obj.id == onEditingPost.value.id
  )

  //待修正一開始會是 undefined 的問題
  if (postForUpdate.value) {
    postList.value[elementIndex].subject = postForUpdate.value.subject
  }

  localStorage.setItem("postList", JSON.stringify(postList.value))

  // 更新文章內容
  localStorage.setItem(
    onEditingPost.value.id,
    JSON.stringify(postForUpdate.value) //如果不多定義一個變數來處理 update，會造成游標跳到最前面
  )
}
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
}
#app {
  display: flex;
}

#nav {
  padding: 0px 16px;
  max-width: 280px;
  width: 100%;
  box-shadow: inset -1px 0 0 0 #ededed;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.appBar {
  height: 56px;
  display: flex;
  box-shadow: inset 0 -1px 0 0 #ededed;
  align-items: center;
}

.logo {
  width: 34px;
  height: 18px;
  background: url("~@/assets/logo.svg");
  display: block;
}

.posts {
  flex: 1;
  padding: 16px 0px;
}

.posts,
.post {
  margin: 0;
}

.posts li {
  list-style: none;
}

.post {
  height: 48px;
  padding: 0px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #000000;
  text-decoration: none;
}

.router-link-active {
  background: #f3f3f3;
}

.post.onEditing {
  background: #f3f3f3;
}

.button {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newPost {
  height: 48px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background: #000000;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
}
</style>
