import { createRouter, createWebHistory } from "vue-router"
import Editor from "../views/Editor.vue"

const routes = [
  {
    path: "/post/:id?",
    name: "Editor",
    component: Editor,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
