<template>
  <div class="container">
    <div class="editor">
      <input
        ref="subjectElement"
        class="subject"
        placeholder="主題"
        @keyup="update"
      />
      <div
        ref="contextElement"
        class="context"
        contenteditable="plaintext-only"
        @keyup="update"
      ></div>
    </div>
    <div class="status">
      <div class="pill">
        <div class="count">{{ count }} / 2,200</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from "vue"

const count = ref("0")

const subjectElement = ref(null)
const contextElement = ref(null)
const context = ref("")

const emit = defineEmits(["update:postUpdate", "update:content"])

const props = defineProps({
  post: Object,
})

watch(
  () => props.post,
  (val) => {
    subjectElement.value.value = props.post.subject
    contextElement.value.innerText = props.post.context
  }
)

const update = () => {
  // 計算
  context.value = contextElement.value.innerText.trim()
  count.value = context.value.length

  // 回傳
  emit("update:postUpdate")
  emit("update:content", {
    id: props.post.id,
    subject: subjectElement.value.value,
    context: context.value,
  })
}
</script>

<style>
.container {
  height: 100vh;
  overflow: scroll;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 0px 64px;
  position: relative;
}

.editor {
  display: block;
  flex-direction: column;
  max-width: 640px;
  width: 100%;
  padding: 64px 0px;
  height: fit-content;
}

.subject,
.context {
  font-size: 18px;
  border: none;
  outline: none;
  width: 100%;
}

.subject {
  font-weight: 500;
  margin-bottom: 40px;
}

.context {
  line-height: 160%;
  min-height: 100%;
  height: auto;
  display: block;
}

.context:empty:before {
  content: "寫點什麼⋯⋯";
  color: gray;
  pointer-events: none;
}
.context:focus:before {
  content: none;
}

.status {
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.pill {
  height: 48px;
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 24px;
  padding: 0px 24px;
  align-items: center;
  font-size: 14px;
  background: #ffffff;
}
</style>
